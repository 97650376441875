<template>
  <div class="section">
    <!--탭메뉴 s-->
    <tab-menu/>
    <!--탭메뉴 e-->

    <p class="tab_exp">나의 활동 이력에서는 선생님께서 BMSON 웹사이트에서의 활동 이력을 조회하실 수 있습니다.</p>

    <!--내용 영역 s-->
    <p class="mp_con_exp">- 선생님의 2022년도 총 누적 기부포인트는 <span>{{ totalPoint }} Point</span> 입니다.</p>
    <div class="mp_history_table_area">
      <table class="mp_history_table" style="table-layout: fixed; overflow: auto; ">
        <colgroup>
          <col style="width: 5em">
          <col style="min-width: 150px">
          <col style="min-width: 150px">
          <col style="width: 70px">
          <col style="width: 100px">
          <col style="width: 150px">
          <col style="width: 7em">
        </colgroup>
        <thead>
        <tr>
          <th>번호</th>
          <th>네비게이션</th>
          <th>콘텐츠</th>
          <th>활동 구분</th>
          <th>기록</th>
          <th>일시</th>
          <th>기부 포인트</th>
        </tr>
        </thead>
        <tbody>

        <tr v-for="(item, index) in list" :key="index">
          <td class="td_list" v-text="totalElements - index"></td>
          <td class="td_list" style="text-align: start; "
              v-html="$options.filters.supTag(item.nav)"></td>
          <td class="bold td_list" style="text-align: start; "
              v-html="$options.filters.supTag(item.content)"></td>
          <td class="td_list">{{ item.actingFootprints }}</td>
          <template>
            <td v-if="item.actingFootprints === '신청'">
              <button id="modal_btn" @click="openInfo(item.originId, item.originType)">신청내용</button>
            </td>
            <td v-else></td>
          </template>
          <td class="td_list">{{ item.bookmarkDate }}</td>
          <td class="td_list">{{ item.donatePoint }}</td>
        </tr>
        </tbody>

      </table>
    </div>

    <div class="btn_area btn_more">
      <button v-if="!isLast" @click="fetchHistoryActingList">Load More</button>
    </div>

    <div class="black_bg"
         v-show="showModal"
         style="display: block"
         :modalInfo="modalInfo"
         :modalTitleType="modalTitleType"
         @closeModal="closeInfo"></div>
    <applicate-information
        v-show="showModal"
        style="display: block"
        :modalInfo="modalInfo"
        :modalTitleType="modalTitleType"
        @closeModal="closeInfo"/>
    <!--내용 영역 e-->
  </div>
</template>

<script>
import ApplicateInformation from "@/views/MyPage/ApplicateInformation";
import TabMenu from "@/components/MyMenu/TabMenu";

export default {
  name: "HistoryActing",
  components: {
    TabMenu,
    ApplicateInformation,
  },
  created() {
    this.fetchHistoryActingListInit()
    this.fetchDonateOfSum()
  },
  data() {
    return {
      showModal: false,
      list: [],
      isLast: "",
      page: "",
      totalElements: "",
      totalPoint: "",
      modalInfo: [],
      modalTitleType: "",
    }
  },
  methods: {
    async fetchDonateOfSum() {
      const {data: {data: value}} = await this.$store.dispatch('fetchDonateOfSum')
      this.totalPoint = value;
    },
    async fetchHistoryActingListInit() {
      const {historyActingList, isLast, page, totalElements} = await this.$store.dispatch(
          'fetchHistoryActingListInit')
      this.list = historyActingList
      this.isLast = isLast
      this.page = page
      this.totalElements = totalElements
    },
    fetchHistoryActingList() {
      this.page++
      this.$store.dispatch('fetchHistoryActingList', {
        page: this.page
      })
      .then(data => {
        const {historyActingList, isLast, page} = data
        this.list = historyActingList
        this.isLast = isLast
        this.page = page
      }).catch(error => {
        console.log(error)
      })
    },
    openInfo(id, type) {
      if (type === "visit-request") {
        this.modalTitleType = "BMS직원방문 및 요청"
      } else if (type === "medical-request") {
        this.modalTitleType = "의학정보요청"
      } else if (type === "abnormal-report") {
        this.modalTitleType = "이상사례보고"
      }
      this.$store.dispatch('getModalInfo', {
        type: type,
        id: id,
      }).then(data => {
        this.modalInfo = data.data.value
      })
      this.showModal = true;
      if (this.showModal) {
        document.documentElement.classList.add("overflow-y-hidden");
      }
    },
    closeInfo() {
      this.showModal = false;
      if (!this.showModal) {
        document.documentElement.classList.remove("overflow-y-hidden");
      }
    },

  },
  filters: {
    supTag: function (value) {
      return value.replace(/[®™]/gi, '<sup>$&</sup>');
    }
  },
};
</script>

<style scoped>
.td_list {
  /*padding-left: 10px;*/
  /*padding-right: 10px;*/
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

</style>

<!--<tr>-->
<!--<td>5</td>-->
<!--<td>Home</td>-->
<!--<td class="bold">BMSON Main</td>-->
<!--<td>로그인</td>-->
<!--<td></td>-->
<!--<td>2021.08.23 17:55:53</td>-->
<!--<td>20</td>-->
<!--</tr>-->

<!--<tr>-->
<!--<td>4</td>-->
<!--<td>Home > 오렌시아(아바타셉트) > 제품브로셔</td>-->
<!--<td class="bold">(특정 뉴스 & 공지 상세 페이지 제목 조회)</td>-->
<!--<td>조회</td>-->
<!--<td></td>-->
<!--<td>2021.08.23 17:55:53</td>-->
<!--<td>20</td>-->
<!--</tr>-->

<!--<tr>-->
<!--<td>3</td>-->
<!--<td>Home > 의학정보 요청 및 이상사례보고</td>-->
<!--<td class="bold">의학정보요청</td>-->
<!--<td>신청</td>-->
<!--<td>-->
<!--  <button id="modal_btn" @click="openInfo">신청내용</button>-->
<!--</td>-->
<!--<td>2021.08.23 17:55:53</td>-->
<!--<td>20</td>-->
<!--</tr>-->
