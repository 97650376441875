<!--나의 활동 이력 -> 신청 내용 부분(모달)입니다. -->
<template>
  <div class="modal_wrap_840">
    <div class="modal_close" role="link" @click="closeModal">
      <a>close</a>
    </div>

    <h1>신청서비스 <span>{{ modalTitleType }}</span></h1>

    <dl>
      <dt>제품</dt>
      <dd>{{modalInfo.productName}}</dd>

      <dt v-if="modalTitleType !== '이상사례보고'">종류</dt>
      <dd>{{ modalInfo.howVisit }}</dd>

      <dt>내용</dt>
      <dd>내용은 BMSON 데이터베이스에 저장하지 않고 담당자와 선생님의 메일로만 발송됩니다. <br>선생님의 메일함을 확인해주세요.</dd>

      <dt>신청일시</dt>
      <dd>{{modalInfo.registrationDate}}</dd>
    </dl>

    <div class="modal_close2" role="link" @click="closeModal">
      <a>확 인</a>
    </div>
  </div>
</template>
<script>
export default {
  name: "ApplicationInformation",
  props: {
    modalInfo: {},
    modalTitleType: {},
  },
  mounted() {
    console.log("mounted : " + this.modalTitleType)
  },
  methods: {
    closeModal() {
      this.$emit("closeModal")
    }
  }
}
</script>
<style>

</style>
